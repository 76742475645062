<template>
  <div>
    <section class="header">
      <video src="../../../assets/images/homePage/video_bg.mp4" playsinline muted autoplay loop preload>
        <source src="../../../assets/images/homePage/video_bg.mp4" type="video/webm" />
      </video>
      <div class="text_box" id="textBox">
        <span class="text1">金融短视频赛道 细分领域专家</span><br />
        <strong class="text2">灯影 畅游未来</strong><br />
        <b class="text3"
          >灯影致力于为金融机构提供MAU增长、业务增长、降本<br />增效、营销获客等多种解决方案，助力金融机构提升智能化水平</b
        >
      </div>
    </section>
    <section class="part part1">
      <div class="part_title_box">
        <strong class="part_title">产品特性</strong><br />
        <span class="part_sub_title">Product Features</span>
      </div>
      <ul class="part_contain_box">
        <li>
          <i></i><br />
          <strong>视频制作</strong><br />
          <span>video producing</span>
        </li>
        <li>
          <i></i><br />
          <strong>视频互动</strong><br />
          <span>Interactive video</span>
        </li>
        <li>
          <i></i><br />
          <strong>智能视频</strong><br />
          <span>intelligent video</span>
        </li>
        <li>
          <i></i><br />
          <strong>智能投顾</strong><br />
          <span>robo-advisor</span>
        </li>
        <li>
          <i></i><br />
          <strong>产业智能化</strong><br />
          <span>Industrial intelligentization</span>
        </li>
      </ul>
    </section>
    <section class="part part2">
      <div class="part_title_box">
        <strong class="part_title">视频制作</strong><br />
        <span class="part_sub_title">Video Production</span>
      </div>
      <ul class="part_contain_box">
        <li>
          <strong>数据管理</strong><br />
          <span>data administration</span>
        </li>
        <li>
          <strong>视频编辑</strong><br />
          <span>video editing</span>
        </li>
        <li>
          <strong>视频录制</strong><br />
          <span>record video</span>
        </li>
        <li>
          <strong>视频发布</strong><br />
          <span>Video released</span>
        </li>
        <li>
          <strong>视频统计</strong><br />
          <span>Video statistics</span>
        </li>
        <li>
          <strong></strong><br />
          <span>authority management</span>
        </li>
      </ul>
    </section>
    <section class="part part3">
      <div class="part_title_box">
        <strong class="part_title">视频互动</strong><br />
        <span class="part_sub_title">Interactive video</span><br />
        <span>通过优质内容形成粘性用户，提供品宣手段引流沉淀，数据分析能营销，客系管理反哺内容升级</span>
      </div>
      <div id="trigger3" style="height: 1px"></div>
      <div class="part_contain_box">
        <div class="left" id="part3Left">
          <div class="tab tab1" @click="handleTabClick(1)"></div>
          <div class="tab tab2" @click="handleTabClick(2)"></div>
          <div class="tab tab3" @click="handleTabClick(3)"></div>
          <div class="tab tab4" @click="handleTabClick(4)"></div>
        </div>
        <div class="right" id="part3Right">
          <div class="contain1" v-if="activeTab == 1"></div>
          <div class="contain2" v-if="activeTab == 2"></div>
          <div class="contain3" v-if="activeTab == 3"></div>
          <div class="contain4" v-if="activeTab == 4"></div>
        </div>
      </div>
    </section>
    <section class="part part4">
      <div class="part_title_box">
        <strong class="part_title">智能视频</strong><br />
        <span class="part_sub_title">intelligent video</span>
      </div>
      <div id="trigger1" style="height: 1px"></div>
      <ul class="part_contain_box">
        <li id="part4Left">
          <strong>传统视频模板</strong>
          <div>
            <span>模板内容千篇一律</span><br />
            <span>运营效果差</span><br />
            <span>应用范围少</span><br />
            <span>热点追踪不及时</span><br />
            <span>用户被动接受</span>
          </div>
        </li>
        <li id="part4Right">
          <div>
            <span>模板内容千人千面</span><br />
            <span>仿人工运营</span><br />
            <span>热点实时追踪推送</span><br />
            <span>结合用户偏好智能生成</span><br />
            <span>增强用户交互行为</span>
          </div>
          <strong>智能视频模板</strong>
        </li>
      </ul>
    </section>
    <section class="part part5">
      <div class="part_title_box">
        <strong class="part_title">智能投顾</strong><br />
        <span class="part_sub_title">Robo-advisor</span>
      </div>
      <div id="trigger2" style="height: 1px"></div>
      <ul class="part_contain_box">
        <li id="part5Left">
          <strong>图像合成技术</strong><br />
          <i class="line1"></i><br />
          <i class="line2"></i><br />
          <span>Wav2Lip</span>
        </li>
        <li id="part5Right">
          <strong>3D&智能技术</strong><br />
          <span>剧本编辑/直播系统</span>
        </li>
      </ul>
    </section>
    <section class="part part6">
      <div class="part_title_box">
        <strong class="part_title">产业智能化</strong><br />
        <span class="part_sub_title">Industrial intelligentization</span>
      </div>
      <ul class="part_contain_box">
        <li class="item">
          <span class="circle circle1">业务模型</span>
          <span class="circle circle2">知识体系</span>
          <span class="circle circle3">3D主播</span>
          <span class="circle circle4">灯塔表哥</span>
        </li>
        <li class="bg"><span>智能化·情感化互动</span></li>
        <li class="item">
          <span class="circle circle1">分析师</span>
          <span class="circle circle2">智能客服</span>
          <span class="circle circle3">新闻主播</span>
          <span class="circle circle4">投教老师</span>
          <span class="circle circle5">投资顾问</span>
        </li>
        <li class="bg"><span>证券业务</span></li>
        <li class="item">
          <span class="circle circle1">智能交易</span>
          <span class="circle circle2">智能投顾</span>
          <span class="circle circle3">智能客服</span>
          <span class="circle circle4">智能资讯</span>
          <span class="circle circle5">智能监管</span>
          <span class="circle circle6">智能运营</span>
        </li>
      </ul>
    </section>
    <section class="kefu_section">
      <div class="kefu_holder">联系我们</div>
      <div class="kefu_box">
        <div class="kefu_title">添加专属客服</div>
        <div class="kefu_tip">（请备注：姓名+公司+合作意向）</div>
        <img class="kefu_ewm" src="../../../assets/images/homePage/ewm_kefu.jpg" alt="" />
        <div class="kefu_txt">立即扫码添加我吧！</div>
      </div>
    </section>
  </div>
</template>
<script>
import ScrollReveal from "scrollreveal";
import anime from "animejs/lib/anime.es.js";
export default {
  data() {
    return {
      activeTab: 1,
      scrollReveal: ScrollReveal(),
    };
  },
  methods: {
    init() {
      this.scrollReveal.reveal("#textBox", {
        origin: "top",
        distance: "-100px",
        duration: 1000,
        reset: true,
      });
      this.scrollReveal.reveal(".part_title_box", {
        origin: "top",
        distance: "-100px",
        duration: 1000,
        reset: true,
      });
      this.scrollmagic({
        trigger: "#trigger3",
        duration: 300,
        ele: "#part3Left",
        // offset: 100,
        css: { transform: "translateX(0)", opacity: 1 },
        callback: (e) => {
          console.log(e);
          if (e.progress == 1) {
            this.timer = setInterval(() => {
              this.activeTab++;
              if (this.activeTab > 4) {
                this.activeTab = 1;
              }
            }, 1500);
          } else {
            clearInterval(this.timer);
          }
        },
      });
      this.scrollmagic({
        trigger: "#trigger3",
        duration: 300,
        ele: "#part3Right",
        // offset: 100,
        css: { transform: "translateX(0)", opacity: 1 },
      });
      this.scrollmagic({
        trigger: "#trigger1",
        duration: 300,
        ele: "#part4Left",
        // offset: 100,
        css: { transform: "translateX(0)", opacity: 1 },
      });
      this.scrollmagic({
        trigger: "#trigger1",
        duration: 300,
        ele: "#part4Right",
        // offset: 100,
        css: { transform: "translateX(0)", opacity: 1 },
      });
      this.scrollmagic({
        trigger: "#trigger2",
        duration: 300,
        ele: "#part5Left",
        // offset: 100,
        css: { transform: "translateX(0)", opacity: 1 },
      });
      this.scrollmagic({
        trigger: "#trigger2",
        duration: 300,
        ele: "#part5Right",
        // offset: 100,
        css: { transform: "translateX(0)", opacity: 1 },
      });

      this.animeCircle();
    },
    animeCircle() {
      anime({
        targets: ".part6 .circle",
        translateX: function () {
          return anime.random(0, 50);
        },
        translateY: function () {
          return anime.random(0, 50);
        },
        easing: "linear",
        duration: function () {
          return 1000;
        },
        // delay: function(el, i) {
        //   return i * 200
        // },
        complete: this.animeCircle,
        // loop: true
      });
    },
    scrollmagic({ trigger, ele, duration, offset = 0, css, callback }) {
      const scene = this.$scrollmagic.scene({
        triggerElement: trigger,
        // {0,0.5,1} - animations starts from {top,center,end} of window
        triggerHook: 0.8,
        // Duration of animation
        duration: duration,
        offset: offset,
      });
      scene
        .on("end", function (e) {
          callback && callback(e);
        })
        // Declaration of animation and attaching to element
        .setTween(
          TweenMax.to(ele, 0.5, {
            css: css,
          })
        );
      this.$scrollmagic.addScene(scene);
    },
    handleTabClick(index) {
      clearInterval(this.timer);
      if (this.activeTab == index) return;
      this.activeTab = index;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
